]<!-- =========================================================================================
    File Name: Faq.vue
    Description: FAQ Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="faq-page">
        <!-- JUMBOTRON -->
        <div class="faq-jumbotron">
            <div class="faq-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
                <h1 class="mb-1 text-white">Desteğe İhtiyacınız mı Var?</h1>
                <p class="text-white">Ceddan Bilişim çözümleriyle İşinizi uçtan uca yönetin.</p>
                <p class="text-white">IP Adresiniz: <b>{{sIp}}</b></p>
                <vs-input placeholder="Search" v-model="faqSearchQuery" icon-pack="feather" icon="icon-search" size="large" class="w-full mt-6" icon-no-border />
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full md:w-2/5 lg:w-1/4 rounded-lg">
                <vx-card>
                    <h4>Kategori</h4>
                    <ul class="faq-topics mt-4">
                        <li v-for="category in categories" :key="category.id" class="p-2 font-medium flex items-center" @click="faqFilter = category.id">
                            <div class="h-3 w-3 rounded-full mr-2" :class="'bg-' + category.color"></div><span class="cursor-pointer">{{ category.name }}</span>
                        </li>
                    </ul>

                    <br><br>

                    <h4>Personellerimiz</h4>
                    <ul class="faq-supporters mt-4">
                        <li v-for="supporter in supporters" :key="supporter.id" class="mt-4">
                            <div class="flex items-center">
                                <vs-avatar class="mr-3" :src="require(`@/assets/images/portrait/small/${supporter.img}`)" size="35px" />
                                <div class="leading-tight">
                                    <p class="font-semibold">{{ supporter.name }}</p>
                                    <small>{{ supporter.profession }}</small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </vx-card>
            </div>

            <!-- FAQ COL -->
            <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0">
                <vs-collapse accordion type="margin" class="p-0">
                    <vs-collapse-item v-for="(que,index) in filteredFaq" class="faq-bg rounded-lg" :class="{'mt-0': !index}" :key="que.id">
                        <div slot="header"><h5>{{ que.question }}</h5></div>
                        <p v-html="que.ans">{{ que.ans }}</p>
                    </vs-collapse-item>
                </vs-collapse>
                
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default{
    data() {
        return {
            faqSearchQuery: '',
            sIp:'',
            faqFilter: 1,
            categories: [
                {
                    id: 1,
                    name: 'Tümü',
                    color: 'grey',
                },
                {
                    id: 2,
                    name: 'Genel',
                    color: 'primary'
                },
                {
                    id: 3,
                    name: 'Sürücüler',
                    color: 'success'
                },
                {
                    id: 4,
                    name: 'Kurulum',
                    color: 'warning'
                },
                {
                    id: 5,
                    name: 'Uzak Destek',
                    color: 'danger'
                },
            ],
            faqs: [
                {
                    id: 1,
                    categoryId: 5,
                    question: 'Uzaktan Destek Programları',
                    ans: '<ul class="faq-topics mt-4"><li class="p-2 font-medium flex items-center"><a target="_blank" href="support/Anydesk.exe">AnyDesk</a></li><br> <li class="p-2 font-medium flex items-center"><a href="support/TeamViewerQS.exe">Teamviewer </a></li><br> <li class="p-2 font-medium flex items-center"><a href="support/Alpemix.exe">Alpemix</a> </li><br><li class="p-2 font-medium flex items-center"> <a href="support/Ammyy.exe">Ammyy</a></li></ul>'
                },
                {
                    id: 3,
                    categoryId: 3,
                    question: 'Barkod Yazıcı Sürücüleri',
                    ans: '<ul class="faq-topics mt-4"><li class="p-2 font-medium flex items-center"><a target="_blank" href="https://www.seagullscientific.com/support/downloads/drivers/argox/">Argox Yazıcı Sürücüleri</a></li> <br> <li class="p-2 font-medium flex items-center"><a target="_blank" href="https://www.seagullscientific.com/support/downloads/drivers/datamax-oneil/">Datamax Yazıcı Sürücüleri</a></li>  <br> <li class="p-2 font-medium flex items-center"><a target="_blank" href="https://www.possify.info/ftp/04-Barkod Yazıcılar/1-Barkod Yazıcı Sürücüsü.rar">Possify 425X</a></li></ul>'
                },
                {
                    id: 2,
                    categoryId: 4,
                    question: 'Kurulum Dosyaları',
                    ans: '<ul class="faq-topics mt-4"><li class="p-2 font-medium flex items-center"><a target="_blank" href="https://www.microsoft.com/en-us/download/details.aspx?id=50003"> MSSQL SERVER 2012 (X86,X64)</a></li> <br><li class="p-2 font-medium flex items-center"><a target="_blank" href="https://www.microsoft.com/en-us/download/details.aspx?id=42299">MSSQL SERVER 2014 (X86,X64)</a></li><br><li class="p-2 font-medium flex items-center"><a target="_blank" href="https://www.barkodyazilimevi.com.tr/Setups/SETUPS.exe">Karpin Setup</a></li><br><li class="p-2 font-medium flex items-center"><a target="_blank" href="https://www.barkodyazilimevi.com.tr/Setups/20_2.rar" >Karpin Güncel Versiyon</a></li><br><li class="p-2 font-medium flex items-center"><a target="_blank" href="https://www.barkodyazilimevi.com.tr/Setups/BARKOD_MAGAZA.rar">Karpin Ana Exe</a></li></ul>'
                },
                {
                    id: 3,
                    categoryId: 1,
                    question: 'Diğer Programlar',
                    ans: '<ul class="faq-topics mt-4"><li class="p-2 font-medium flex items-center"><a target="_blank" href="support/CEDDAN1.rar">Ceddan1</a></li><br><li class="p-2 font-medium flex items-center"><a target="_blank" href="support/IPSCAN.exe">IPSCAN</a></li><br><li class="p-2 font-medium flex items-center"><a target="_blank" href="https://www.rarlab.com/rar/winrar-x64-600tr.exe">winrar 6.0 Türkçe</a></li><br><li class="p-2 font-medium flex items-center"><a target="_blank" href="support/digiterazi.zip">Digi Programı</a></li><br><li class="p-2 font-medium flex items-center"><a target="_blank" href="support/firebird.rar">Firebird</a></li><br><li class="p-2 font-medium flex items-center"><a target="_blank" href="support/ishida.gdb">GDB File</a></li></ul>'
                },
                {
                    id:4,
                    categoryId:1,
                    question:'Tasarım Dosyaları',
                    ans:'<ul class="faq-topics mt-4"><li class="p-2 font-medium flex items-center"><a target="_blank" href="support/formlar.rar">Etiket Tasarımları</a></li></ul>'
                }
            ],
            supporters: [
                {
                    id: 1,
                    img: 'avatar-s-1.png',
                    name: 'Ömer Faruk DEMİRYÜREK',
                    profession: 'Genel'
                },
                
                {
                    id: 3,
                    img: 'avatar-s-21.png',
                    name: 'Yusuf TAVŞAN',
                    profession: 'Eğitim Destek'
                },
                
                {
                    id: 5,
                    img: 'avatar-s-23.png',
                    name: 'Kadir ÇAKIR',
                    profession: 'Yazılım Geliştirme'
                },
            ]
        }
    },
    computed: {
        filteredFaq() { 
            return this.faqs.filter((faq) => {
                if (this.faqFilter == 1) return (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 2) return faq.categoryId == 2 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 3) return faq.categoryId == 3 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 4) return faq.categoryId == 4 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 5) return faq.categoryId == 5 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
            });
        }
    },
    methods: {
    },
    components: {
    },
    mounted(){
        axios('https://api.ipify.org?format=json').then(response => {
       this.sIp=response.data.ip;
       
       });
    }
}
</script>

<style lang="scss">
#faq-page {
    .faq-jumbotron-content {
        background-image: url('../../assets/images/pages/faq.jpg');
        background-size: cover;
    }

    .faq-bg {
        background-color: #fff;
    }   
}
</style>